<template>
  <div>
    <div class="import-title background__blue_title">
      <p class="title-secondary fs-20 color__blue_main fw__bold p--0">{{ $t('import_mail.title') }}</p>
    </div>
    <div class="page-main-content">
      <div class="columns is-justify-content-center">
        <div class="column is-9">
          <div class="card has-text-centered box-upload"
               :class="{'border-error': messageFileInvalid}"
               @dragover="dragOver"
               @dragleave="dragLeave"
               @drop="dropFile">
            <input type="file"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                   ref="fileUpload"
                   class="input-upload"
                   id="file-import"
                   @change="getFileImport($event)">
            <img class="icon-upload m__left--0 m__top--70"
                 src="@/assets/svgs/upload_image.svg">
            <p class="m__top--30 color__blue_main fw__bold fs-20">{{ $t('import_mail.file.content') }} </p>
            <p class="m__top--20 fs-14">{{ $t('import_mail.file.or') }}</p>
            <label class="button p-0 btn-save background__blue_button_add br--10 m__top--30 m__bottom--50"
                   for="file-import">{{ $t('import_mail.file.select') }}</label>
          </div>
          <span v-if="messageFileInvalid" class="color-error m__top--5">
            {{ messageFileInvalid }}
          </span>
        </div>
      </div>
      <div class="columns" v-if="file && file.name">
        <div class="column">
          <p class="m__bottom--10">{{ $t('import_mail.file.info') }}</p>
          <div class="table-container data-table m__bottom--10">
            <table class="table is-striped is-hoverable is-fullwidth">
              <thead>
              <tr>
                <th class="input-title p--12">{{ $t('import_mail.fields.name') }}</th>
                <th class="input-title p--12">{{ $t('import_mail.fields.creator') }}</th>
                <th class="input-title p--12">{{ $t('import_mail.fields.created_at') }}</th>
                <th class="input-title p--12">{{ $t('import_mail.fields.total') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td :class="{'color-error': messageFileMaxRows}">{{ file.name }}</td>
                <td :class="{'color-error': messageFileMaxRows}">{{ file.creator }}</td>
                <td :class="{'color-error': messageFileMaxRows}">{{ file.createdDate }}</td>
                <td :class="{'color-error': messageFileMaxRows}">{{ file.totalRecords }} 件</td>
              </tr>
              </tbody>
            </table>
          </div>
          <span v-if="messageFileMaxRows" class="color-error m__top--5">
            {{ messageFileMaxRows }}
          </span>
        </div>
      </div>

      <div class="columns" v-if="file.name && validationErrors && toArray(validationErrors).length">
        <div class="column d-grid">
          <p class="m__bottom--10">{{ $t('import_mail.list.result') }}</p>
          <div class="table-container data-table table-validation">
            <table class="table is-striped is-hoverable is-fullwidth">
              <thead>
              <tr>
                <th class="input-title p--12 vertical-middle has-text-centered">{{ $t('import_mail.list.line') }}</th>
                <th class="input-title p--12 vertical-middle">{{ $t('import_mail.list.result') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(e, i) in toArray(validationErrors)" :key="'error-' + i">
                <td class="vertical-middle has-text-centered">{{ objKeys(validationErrors)[i] }}</td>
                <td>
                  <ul v-if="isObject(e)">
                    <li v-for="(err, index) in objKeys(e)" :key="'err-' + index">
                      <template v-if="err !== 'key'">
                        <span v-for="(item, id) in e[err]" :key="'err-item-' + id" class="has-text-danger">{{
                            item
                          }}</span>
                      </template>
                    </li>
                  </ul>
                  <span class="has-text-success" v-else>{{ e }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="columns" v-if="file && file.name && isSubmitted">
        <div class="column is-full">
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button @click="cancelImport()" class="button btn-cancel background__grey">{{ $t('buttons.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="columns" v-if="file && file.name && !isSubmitted && file.totalRecords >= 0">
        <div class="column is-full">
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button @click="importFile()" class="button p-0 btn-save background__blue_main">取込</button>
            </div>
            <div class="control">
              <button @click="cancelImport()" class="button btn-cancel background__grey">{{ $t('buttons.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions"
import ImportMailAddressService from "@/services/ImportMailAddressService"
import XLSX from 'xlsx'
import {mapGetters} from "vuex"
import {currentDateTime} from '@/filters'
import toArray from 'lodash/toArray'
import isObject from 'lodash/isObject'

export default {
  name: "ImportMailAddress",
  data() {
    return {
      fileUpload: null,
      filePreview: '',
      file: {
        name: '',
        creator: '',
        createdDate: '',
        totalRecords: null
      },
      validationErrors: [],
      fileKeys: [],
      isSubmitted: false,
      submitAvailable: true,
      messageFileInvalid: '',
      messageFileMaxRows: '',
    }
  },
  computed: {
    ...mapGetters(['authUser']),
  },
  watch: {
    filePreview(val) {
      if (val) {
        this.file.totalRecords = val.length
        if (this.file.totalRecords > 2001) {
          this.messageFileMaxRows = this.$t('import_mail.messages.file_import_max_rows')
        }
        this.fileKeys = Object.keys(val[0])
      }
    }
  },
  methods: {
    objKeys(obj) {
      return Object.keys(obj)
    },

    isObject(val) {
      return isObject(val)
    },

    toArray(val) {
      return toArray(val)
    },

    dragOver(event) {
      event.target.closest('.box-upload').classList.add('is-hovering')
      event.preventDefault();
    },

    dragLeave(event) {
      event.target.closest('.box-upload').classList.remove('is-hovering')
    },

    dropFile(event) {
      // event.target.closest('.box-upload').classList.remove('is-hovering')
      event.preventDefault()
      this.$refs.fileUpload.files = event.dataTransfer.files
      this.getFileImport()
    },

    getFileType(filename) {
      let parts = filename.split('.')
      return parts[parts.length - 1]
    },

    isExcel(ext) {
      let paths = ['xls', 'xlsx']
      return paths.includes(ext)
    },

    excelToJSON(file) {
      let reader = new FileReader()
      reader.onload = (e) => {
        let data = e.target.result
        let workbook = XLSX.read(data, {
          type: 'binary'
        })
        workbook.SheetNames.forEach((sheetName) => {
          this.filePreview = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
        })
      }
      reader.onerror = (ex) => {
        console.log(ex)
      }
      reader.readAsBinaryString(file)
    },

    getFileImport() {
      if (this.$refs.fileUpload && this.$refs.fileUpload.files[0]) {
        this.messageFileInvalid = ''
        this.messageFileMaxRows = ''
        this.isSubmitted = false
        this.validationErrors = []
        this.fileUpload = this.$refs.fileUpload.files[0]
        let ext = this.getFileType(this.fileUpload.name)
        if (!this.isExcel(ext)) {
          this.messageFileInvalid = this.$t('import_mail.messages.invalid_file_import')
        } else {
          this.excelToJSON(this.fileUpload)
          this.file.name = this.fileUpload.name
          this.file.creator = this.authUser.name
          this.file.createdDate = currentDateTime()
        }
      }
    },

    importFile() {
      if (!this.messageFileInvalid && !this.messageFileMaxRows) {
        this.isSubmitted = true
        let formData = new FormData()
        formData.append('file', this.fileUpload);
        if (this.submitAvailable) {
          this.submitAvailable = false
          ImportMailAddressService.import(formData).then((res) => {
            this.submitAvailable = true
            this.validationErrors = res.data
            this.$toast.success(this.$t('import_mail.messages.import_success'))
          }).catch(() => {
            this.submitAvailable = true
            this.$toast.error(this.$t('import_mail.messages.import_failed'))
          })
          this.$refs.fileUpload.value = null
        }
      }
    },

    cancelImport() {
      this.$refs.fileUpload.value = null
      this.validationErrors = []
      this.fileUpload = {}
      this.file = {
        name: '',
        creator: '',
        createdDate: '',
        totalRecords: null
      }
      this.isSubmitted = false
    },
  },
  created() {
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'import-mail-address')
  }
}
</script>

<style lang="scss" scoped>
.page-main-content {
  border-radius: 0 0 10px 10px;
  min-height: 72vh;
}
.box-upload {
  padding: 24px;
  border: 2px dashed #A1D5F3;
  border-radius: 10px;
  height: 500px !important;
  box-shadow: none !important;
  .input-upload {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    padding: 0;
  }

  &.border-error {
    border: 2px dashed #D798C1;
    padding: 22px !important;
  }

  //&.is-hovering {
  //  border: 2px dashed #D798C1;
  //  padding: 22px !important;
  //}
  //
  //&:hover {
  //  //background-color: #f5f4f4;
  //  border: 2px dashed blue;
  //  padding: 22px !important;
  //}
}

.table-validation {
  max-height: 500px;
  display: flex;
  overflow-y: auto;
}

.import-title {
  padding: 10px 60px;
  border-radius: 10px 10px 0 0;
}

.icon-upload {
  width: 112px;
  height: 80px;
}

.btn-save {
  border-radius: 18px !important;
  height: 35px !important;
  width: 140px !important;
  border: none !important;
  font-size: 16px !important;
}

.btn-cancel {
  border-radius: 18px !important;
  height: 35px !important;
  width: 140px !important;
  border:  none !important;
  font-size: 14px !important;
  background-color: #E4E4E4 !important;
  color: #979797 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  .import-title {
    padding: 10px 20px !important;
  }
}
</style>
